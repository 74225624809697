.newsletter-button {
  background: #fff;
  border-radius: 20px;
  padding: 10px;
  display: inline-block;

  a {
    color: $brand-primary;
    text-decoration: none;
  }
}

body #header {
  z-index: 30;
  position: relative;

  #_desktop_user_info {
    float: left !important;
    margin: 0px 2px;
    padding-right: 25px;
  }

  #_desktop_language_selector {
    border-right: initial !important;
    border-left: 1px solid #9a9ca4;
    padding: 0 17px 0 25px;
    margin: 14px 25px 9px 0;
  }

  .header-nav .language-selector {
    margin-top: 5px;
  }

  .user-info {
    margin: 0;

    &.not-logged {
      margin: 0;
    }
  }
}

#products, .elementor {

  .product_show_list {
    .product-description {
      padding: 22px 0 24px 15px !important;

      .left-div {
        height: 118px;
      }

      .history-icon {
        transform: translateY(-28px);
      }

      .add-to-fav {
        transform: translateY(-23px);

        .material-icons {
          line-height: 27px;
        }
      }
    }

    .manufacturer {
      top: 23px;
    }

    .manufacturer.is-not-promoted {
      top: 11px;
    }

    .product-price-and-shipping {
      margin-top: 8px;
      line-height: 17px;

      .history-icon, .add-to-fav .material-icons {
        line-height: 29px;
      }
    }

    .info-discount.fa-info-circle {
      float: none;
    }

    .clearfix .product-description .left-div .product-title {
      max-height: 67px;
      min-height: 67px; //for absolute position of icons
      .product-title-name {
        overflow: hidden;
      }

      a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /*sass doesn't compile -webkit-box-orient for the ellipsis effect*/
        -webkit-box-orient: vertical;
        line-height: 20px;
        max-height: 40px;
        text-align: left;
      }
    }

    .arzneimittel {
      position: absolute;
      top: 50%;
      right: -87px;
      transform: translateY(-20px);
    }

  }
}

.featured-products .product-miniature {
  .discount-container {
    height: 24px;

    .info-discount {
      margin: 0;
      padding-top: 4px;
      margin-left: 6px;
    }
  }

  .product-description {
    h3 {
      max-height: none;

      a {
        max-height: 48px;
        overflow: hidden;
        display: block;
        text-align: left;
      }
    }
  }
}

.products-in-category {
  .product-description h1, .product-description h3 {
    font-size: 13px;
    line-height: 23px;
    max-height: none;
    min-height: 0;
    font-weight: 700;

    a {
      max-height: 46px;
      min-height: 46px;
      display: block;
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
    }

    .product-information {
      font-size: 13px;
      display: block;
      padding: 3px 0;
    }
  }
}

#products .regular-price:not(.empty),
.product-discount .regular-price:not(.empty),
.products .regular-price:not(.empty) {
  color: $brand-primary;
  text-decoration: none;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    max-width: 55px;
    width: 70%;
    height: 86%;
    left: -6%;
    top: 14%;
    z-index: 1;
    background-image: url(../img/oldprice.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    -ms-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
    opacity: 0.75;
  }
}

.product-prices {
  .discount-amount {
    display: none;
  }

  .regular-price {
    font-size: 18px;
  }
}

.has-discount.product-price, .has-discount p {
  color: $prod-price;
}

.page-content.page-cms {
  .cms-content {
    margin-bottom: 75px;

    img {
      width: 100%;
      height: auto;
    }

    img.auto {
      width: inherit;
    }

    h1.page-heading {
      text-align: center;
      font-size: $font-size-h2;
      line-height: 25px;
      text-transform: uppercase;
      margin: 73px 0 50px;
      font-family: $headingFontFamily;
      font-weight: 500;
    }

    h2 {
      font-size: $font-size-h2;
      margin: 47px 0 25px;
      padding-bottom: 12px;
      position: relative;
      text-transform: uppercase;
      font-family: $headingFontFamily;

      &:after {
        content: "";
        width: 50px;
        border-bottom: 1px solid $gray-light2;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    h3 {
      font-size: $font-size-h3;
      text-transform: uppercase;
      font-family: $headingFontFamily;
      padding: 15px 0;
    }

    .bottom-border {
      padding-bottom: 84px;
      border-bottom: 1px solid $cms-border;
      margin-bottom: 90px;
    }

    .partners {
      margin-bottom: 75px;
    }

    p {
      color: $cms-text;
      margin-bottom: 12px;

      &.hotline_label {
        margin-bottom: 5px;
      }
    }

    .cms_image_wrapper {
      width: 65%;
      margin: 0 auto;
      position: relative;
      display: table;
      vertical-align: middle;

      .img-part, .text-part {
        display: table-cell;
        padding: 10px 0;
      }

      .img-part {
        width: 20%;

        img {
          max-width: 80px;
        }
      }

      .text-part {
        width: 80%;
        text-align: left;
      }
    }

    .gr {
      padding: 20px 0;
      display: flex;
      align-items: center;

      .col-sm-3 {
        text-align: center;
      }

      img {
        width: auto;
        max-width: 80%;
      }
    }
  }

  .cms-box {
    .list-1 {
      li {
        clear: both;
        float: none;
        margin-bottom: 5px;
        display: inline-block;
        width: 100%;
      }

      em {
        display: inline-block;
        float: left;
        padding-right: 20px;
        line-height: 23px;
        color: $prod-badge;
      }

      span {
        display: inline-block;
        float: left;
        line-height: 20px;
        width: calc(100% - 40px);
        text-align: left;
      }
    }
  }

  .display-flex {
    display: flex;
    align-items: center;
    justify-content: center;

    .align-item {
      width: calc(100% - 2 * 20px);
      text-align: center;

      img {
        max-width: 80%;
      }
    }
  }

  .contact_us_box {
    border-top: 1px solid $prod-badge;
    padding: 10px 0;

    p {
      margin-bottom: 0px;
      line-height: 20px;
    }
  }
}

.cms-id-75 .page-content.page-cms .cms-content {
  margin-bottom: 0;
}

.verkauf-details {
  margin: 10px 20px;

  .margin-top {
    margin-top: 30px;
  }
}

.cms-verkauf-item-details {
  border-bottom: 1px solid $prod-badge;
}

.cms-verkauf-item-details a {
  color: $prod-badge !important;
  cursor: pointer;
}

.cms-margin-top-bottom {
  margin: 30px 0;
}

.cms-content {
  .team_member {
    float: left;
    position: relative;
    width: 100%;
    margin: 0 0 30px 0;

    .member_photo {
      float: left;
      margin-bottom: 0;

      img {
        width: 130px;
        height: 130px;
        margin-left: 30px;
      }
    }

    .member_info {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 45%;
      width: 50%;

      p {
        margin-bottom: 0 !important;
        text-align: left;

        &.member_name, &.member_name span {
          color: $prod-badge;
          font-family: $headingFontFamily;
          font-size: 15px;
        }
      }
    }
  }

  img.actual-width {
    max-width: 120px;
    margin: 0 80px 20px 0;
  }

  .be-you .by-footer {
    margin-top: 30px;
  }

  .be-you .footer-paragraph {
    font-size: 18px;
    font-weight: bold;
    padding-top: 40px;
    line-height: 26px;
  }

  .be-you {
    .be-you-logo {
      margin-right: 32px;
      margin-top: 22px;
    }

    .by-links {
      margin-top: 30px;
    }

    .by-links a {
      color: #154467;
    }

    .curaprox-link {
      font-size: 22px;
    }

    .by-social-links {
      font-size: 18px;
      margin: 13px 0 0 69px;
    }

    .by-social-links a {
      margin: 0 5px;
    }

    .swiss-logo {
      margin-top: 35px;
    }
  }
}

#graduationNotice .info {
  background: $brand-danger;
}

#boxQtyNotice,
#productPersonalizationNotice {
  .info {
    background: #00AFD7;
  }
}

.info-discount,
.info-discount-prod-page,
.product-miniature .info-discount.fa-info-circle {
  display: block;
  float: left;
  margin: 0;
  color: $brand-danger;

  &.fa-info-circle {
    font-size: 24px;
  }
}

.product-miniature .discount-container .info-discount.fa-info-circle {
  font-size: 13px;
}

.discount-information {
  .info-discount-prod-page {
    margin-left: 0;
  }

  .discount-info {
    line-height: 35px;
  }
}

.gratis-prod-label {
  color: $brand-secondary;
  display: block;
}

.social-btns-position {
  .discount-information {
    margin-bottom: 20px;

    .info-discount-prod-page {
      margin-left: 0;
      margin-right: 2px;
      position: relative;
      top: 4px;
      float: none;
    }
  }
}

.procurement-notification.modal {
  text-align: center;

  p {
    margin-bottom: 0;
  }

  .modal-content {
    width: 100%;
  }

  .modal-footer {
    padding-top: 0;
    border: none;
  }
}

.arzneimittel {
  float: right;
  font-size: 22px;
  margin-left: 5px;
  color: #3C3;
}

#product {
  .customer-addon {
    margin-left: 10px;

    .history-icon {
      width: 29px;
      height: 29px;
    }

    .arzneimittel {
      font-size: 29px;
    }
  }
}

.products, .elementor {
  .manufacturer {
    position: absolute;
    right: 25px;
    top: 11px;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 145px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.available-items {
  margin-top: 4px;

  .label {
    font-size: 10px;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: baseline;
    border-radius: .25em;
    white-space: normal;
    display: inline-block;
    padding: 3px 2px;
    font-family: $headingFontFamily;
    line-height: 9px;
    min-height: 16px;
  }

  .label-success {
    background-color: #5cb85c;
    margin-right: 3px;
  }

  .label-warning {
    background-color: #f0ad4e;
  }

  .label-danger {
    background-color: #ff5048;
  }
}

.available-items .label.fewitems, .available-items .label.noitems,
.available-items .fewitems, .available-items .noitems,
.fewitems, .noitems {
  text-align: left;
  max-width: 100%;
  margin-top: 0;
}

.product-line-grid {
  height: 75px;
}

.file-download-btn-cont {
  width: 220px;
  float: left;

  a {
    display: flex;
    align-items: center;
  }
}

.download-files {
  margin-left: 0;
  margin-right: 0;
}

.top-menu a[data-depth="0"] .icon-chevron {
  right: 18px;
}

.top-menu a[data-depth="0"] {
  padding: 18px 25px;
}

.rounded-border,
.cart-item,
#products .product-description .qty-box input.qty-input,
.category-cart .product-description .qty-box input.qty-input,
.featured-products .product-description .qty-box input.qty-input,
.product-accessories .product-description .qty-box input.qty-input,
.product-miniature .product-description .qty-box input.qty-input,
.products-in-category .product-description .qty-box input.qty-input,
#supercheckout-fieldset .quantitybox,
.practice-list-item,
.page-addresses .address,
.page-customer-account .order-wrapper, .category-top
.table-bordered, .table-bordered td,
.table-bordered th,
.box-rounded,
.ordered-articles .search-widget form,
#pagenotfound .page-not-found,
#products .page-not-found,
#pagenotfound .page-not-found .search-widget form,
.page-not-found #search_widget form,
#products .page-not-found .search-widget form,
.js-product-list-selection .select-title,
.js-product-list-selection .pagination li a,
.form-group .form-control,
.page-not-found {
  border: 1px solid $gray-medium;
}

#sorting-top .js-product-list-selection,
.page-addresses .address .address-body h4,
.box-rounded .card-title,
#wrapper .breadcrumb,
#products .searched-for,
.contact-form h3:after,
.contact-rich h4:after,
.page-content.page-cms .cms-content h2:after,
.customer-form section,
#header .blockcart .product {
  border-bottom: 1px solid $gray-medium;
}

.af_filter .af_subtitle:after,
.page-header h1:after,
.left-column-title:after {
  background: $gray-medium;
}

#amazzing_filter ul {
  .af-parent-category:not(.open) .af-toggle-child {
    .visible-on-open {
      background: url('../img/icons/toggle-filter-open.svg') no-repeat center;
      vertical-align: middle;
    }
  }
}

.page-customer-account .order-wrapper .table td,
.page-customer-account .order-wrapper .table th {
  border-color: $gray-medium;
}

#order-items {
  border-right: 1px solid $gray-medium;
}

#order-items hr,
#_desktop_user_info #header-login-content li ul li {
  border-top-color: $gray-medium;
}

.af_filter.manufacturer.short-list ul {
  max-height: 297px;
  overflow-y: auto;

  label {
    position: relative;
  }
}

.search-manufacturer {
  margin: 20px 0px;
  position: relative;

  .search-icon-container {
    position: absolute;
    border: none;
    bottom: 4px;
    right: 3px;
    color: white;
    background: $crd-secondary-color;
    border-radius: 50%;
    padding: 3px;
    margin: 0 2px;
    width: 28px;
    height: 28px;
  }
}

#search-manufacturer-filter {
  background: white;
  border: 1px solid $gray-medium;
  border-radius: 20px;
  position: relative;
  font-size: 14px;
  line-height: 19px;

  &:focus {
    outline: none;
  }

  input::placeholder {
    font-size: 14px;
  }
}

#supercheckout-fieldset .payment-box .total-price-payment-summary p,
.page-content.page-cms p,
.category-cart-summary .total-label,
#products .product-miniature:not(.product_show_list) .manufacturer,
#products .product-miniature:not(.product_show_list) .product-description h1 a,
.tabs .nav-tabs .nav-link,
.products .manufacturer,
#product-details,
.customer-form .form-control-label,
.form-group .label, .form-group label,
.btn-primary, .btn-secondary, .btn-tertiary,
.supercheckout-totals #supercheckout_summary_total_products .title,
.font-size-14,
#notification,
.page-customer-account .alert-warning,
.page-my-account #content .links a span.link-item {
  font-size: 14px;
}

.products .promoted {
  color: #63b5dc;
  font-size: 9px;
  position: absolute;
  right: 35px;
  top: 11px;
  font-weight: normal;
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  backface-visibility: hidden;
}

.sticky + #wrapper,
.sticky + #notifications {
  /*  padding-top: 151px;*/
}

#header.sticky {
  margin-bottom: 72px;
  position: relative;
}

.nav-top {
  background: #fff;
}

#block-history {
  .product_show_list {
    .thumbnail-container img {
      margin-top: 6px;
    }

    .product-description {
      padding: 22px 0 24px 15px !important;

      .product-price-and-shipping {
        .add-to-fav {
          transform: translateY(-10px);
        }

        .history-icon {
          transform: translateY(-11px);
        }
      }
    }
  }
}

.page-not-found {
  background: #fff;
  padding: 1rem;
  font-size: .8125rem;
  color: #878787;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
  border-radius: 10px;

  .search-widget {
    form {
      border-radius: 19px;
    }

    input[type=text] {
      width: 100%;
      line-height: 36px;
      padding: 0 0 0 13px;
      border-radius: 18px;
      margin: 0;
    }
  }
}

.address-personal-info {
  margin-top: 40px;
}

.supercheckout-blocks .cart-item a.label {
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  line-height: 20px;
}

.reorder-cont {
  text-align: center;
  margin-top: 32px;
}

.order-donwload-btns {
  .file-download-btn-cont {
    position: relative;
    width: 33%;

    span {
      display: block;
      padding-left: 36px;
      padding-block: 7px;
    }

    img {
      position: absolute;
    }
  }
}

#order-details-page .back-btn {
  margin-bottom: 20px;
}

#products .thumbnail-container .product-flags .new,
.product-flags .new,
.product-flag.new,
.products-in-category .product-miniature .product-flags .new,
.featured-products .product-miniature .product-flags .new {
  display: none;
}

.request-price {
  font-size: 19px;
  margin: 20px 0;
  color: $crd-primary-color;
  font-weight: bold;
}

#identity .customer-form {
  border-bottom: 1px solid #bbb;
  padding-bottom: 36px;

  section {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.custom-content-box {
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .flex-cms-child {
    margin: 20px 21px;
    text-align: center;

    a {
      display: block;
    }

    h3 {
      margin-top: 11px;
      text-align: center;
    }

    .link-box {
      display: inline-block;
    }
  }

  .banner-section.no-banners a {
    overflow: hidden;
    max-width: 100%;
    display: block;
    border: 1px solid #ebebeb;
    background: $crd-secondary-color;
    color: #fff;
    font-family: NeoSansProMedium;
    font-size: 24px;
    height: 85px;
    text-align: right;
    padding: 49px 15px 14px;
  }

  .left-half {
    width: 50%;
    text-align: right;
    padding-right: 55px;
    margin: 20px 0;
  }

  .right-half {
    width: 50%;
    text-align: left;
    padding-left: 55px;
    margin: 20px 0;
  }
}

.featured-products {
  .product-information {
    font-size: 13px;
    display: block;
    padding: 3px 0;
  }

  .product-description h1 {
    font-size: 13px;
    line-height: 23px;
    max-height: none;
    min-height: 0;
    font-weight: 700;

    a {
      max-height: 46px;
      min-height: 46px;
      display: block;
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  /*padding-top: 200px;*/
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.category-bottom {
  display: none;
}

.term-and-cond {
  padding: 13px 0;
}

#register .btn-primary.open-register {
  width: inherit;
}

#form-register .post-code label.french, .customer-form .post-code label.french {
  margin-top: -6px;
  text-align: left;
}

.add-to-cart-related .info-discount {
  position: absolute;
  right: 17px;
}

#search_widget.ui-front {
  z-index: 9;
}

.info-popup {
  position: relative;

  .info-popup-content {
    display: none;
    padding: 20px;
    border-radius: 20px;
    color: #000;
    box-shadow: 0 0 4px #ccc;
    top: 47px;
    left: -195px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999;
    max-width: 360px;
    background: #fff;
  }

  .fa {
    position: absolute;
    left: -24px;
    top: 7px;
    font-size: 22px;
    cursor: pointer;
    color: $crd-primary-color;

    display: inline-block;
    -moz-animation: pulse 2s infinite linear;
    -o-animation: pulse 2s infinite linear;
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;

  }

  .info-popup-text {
    margin-bottom: 13px;
  }

  .tooltip-arrow {
    width: 70px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    top: -26px;

    &:after {
      content: "";
      position: absolute;
      left: 20px;
      bottom: 15px;
      width: 25px;
      height: 25px;
      box-shadow: -5px -5px 5px -4px #ccc;
      transform: rotate(45deg);
      background: white;
      border: 2px solid white;
    }
  }

  .hide-all-popups, .reset-all-popups {
    border: none;
    background: none;
    color: $crd-primary-color;
    margin-top: 10px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

#search-popup {
  .fa {
    top: 14px;
    color: white;
  }

  .info-popup-content {
    top: 53px;
  }
}

#filters-popup {
  position: absolute;
  left: -18px;
  top: 0;
  z-index: 2;

  .info-popup-content {
    width: 270px;
    left: -150px;
  }
}

#account-popup {
  .info-popup-content {
    width: 270px;
    left: -148px;
  }
}

#reset-general-popup {
  position: fixed;
  bottom: 100px;
  left: 30px;
  z-index: 3;

  .fa {
    font-size: 25px;
  }

  .info-popup-content {
    top: -139px;
    left: 31px;
    width: 339px;
  }

  .tooltip-arrow {
    left: 0;
    top: auto;
    margin-left: -33px;
    bottom: -2px;

    &:after {
      box-shadow: -3px 3px 3px -1px #ccc;
    }
  }
}

.academy-backlink {
  text-align: center;
  margin-top: 25px;

  a {
    display: block-inline;
  }

  a + a {
    margin-left: 20px;
  }
}

#footer {
  z-index: 4;
  position: relative;
}

#wrapper {
  #search-popup {
    display: none !important;
  }
}

.sticky {
  #account-popup, #login-popup {
    .info-popup-content {
      display: none !important;
    }
  }
}

.dateFilter.hidden {
  display: none;
}

.discount-container {
  position: absolute;
  bottom: -3px;
  right: 0px;

  .info {
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    box-shadow: 0 0 4px #ff4c4c;
    top: 47px;
    left: -160px;
    border: 1px solid #ff4c4c;
    position: absolute;
    z-index: 3;
    width: 360px;
    background: #ff4c4c;
    font-size: 14px;
    line-height: 1.3em;
    text-align: center;

    .close {
      position: absolute;
      right: 6px;
      top: 12px;
      margin-top: -14px;
      transform: rotate(45deg);
      color: #fff;
      text-shadow: none;
      opacity: 1;
    }

    .tooltip-arrow {
      position: absolute;
      width: 70px;
      height: 60px;
      border-color: transparent;
      border-style: solid;
      overflow: hidden;
      left: 50%;
      margin-left: -35px;
      top: -26px;

      &:after {
        content: "";
        position: absolute;
        left: 20px;
        bottom: 15px;
        width: 25px;
        height: 25px;
        box-shadow: -5px -5px 5px -4px #ff4c4c;
        transform: rotate(45deg);
        background: #ff4c4c;
        border: 2px solid #ff4c4c;
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  100% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
}

@-moz-keyframes pulse {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  100% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
}

@-o-keyframes pulse {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  100% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
}

@-ms-keyframes pulse {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  100% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  50% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
  }
  100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.footer-container li {
  margin-bottom: 9px;
}

#supercheckout_summary_small_order_tax {
  .title, .value {
    color: #ff5048;
  }
}

//on focus highlight input


.form-group input[type=text]:focus,
.form-group input[type=text]:hover,
.form-group input[type=password]:focus,
.form-group input[type=password]:hover,
.form-group select:focus,
.form-group select:hover {
  box-shadow: 0 0 5px $crd-primary-color;
}

.modal-footer.align-center {
  justify-content: center;
}

.gritter-notification-btns {
  display: flex;
  justify-content: space-between;

  .btn-primary {
    background: white;
    color: $brand-primary;
  }

  .btn-primary:hover {
    background: $brand-primary;
    color: white;
  }
}

.btn-off-white {
  color: $crd-secondary-color;
  border: 2px solid $crd-secondary-color;
  height: 46px;
  min-width: 215px;
  max-width: 100%;
  border-radius: 24px;
  text-transform: none;
  line-height: 42px !important;
  background: none;

  &:hover {
    border-color: $crd-secondary-color;
  }
}

.gritter-update-success {
  font-size: 13px;
  display: block;
  padding: 16px 10px;
  color: white;
}

#gritter-notice-wrapper .gritter-close {
  display: block !important;
  right: 3px;
  left: auto;
  background: none;
  border: none;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 17px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  &:before {
    content: "\f00d";
  }
}

#module-supercheckout-supercheckout {
  .gritter-notification-btns {
    display: none;
  }
}

.supercheckout-blocks .page-header .print_cart {
  text-align: right;
}

.ordered-articles .search-widget input[type=text] {
  height: 36px;
}

.special-tax {
  margin: 10px 0 0;
  color: $gray-dark;
  font-weight: normal;
}

.product-line-info .special-tax {
  margin: 2px 0;
}

.product-line-grid {
  height: 89px;
}

.practice-list {
  li {
    width: 100%;
  }
}

.list-group-item.row {
  display: flex;

}

.breadcrumb.page-breadcrumb.mt-small {
  display: block;
}

.special-prod-tax {
  font-size: 11px;
  font-style: italic;
}

.expandable-menu-ch7 {
  > li.category {
    position: relative;

    .popover {
      left: -15px !important;
      top: 53px;
    }
  }
}

.contact-rich h4 {
  font-size: 22px;
}

.page-header + .description {
  margin-bottom: 35px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    padding-bottom: 0;
  }
}

#module-crd_outstandinginvoice-outstanding {
  .description {
    margin: 30px 0;
  }
}

#newsletter-text {
  color: white;
  margin: 25px auto 30px;
  width: 450px;
  max-width: 85%;
}

#subscribe-newsletter {
  background: none;
  border: none;
  color: $crd-primary-color;
}

.newsletter-border {
  text-align: center;
  border: 1px solid $footer-border;
  border-left: 0;
  border-right: 0;
  padding: 20px 0 40px;
  margin: 0;
  background: $brand-info;

  .empty-space {
    height: 66px;
  }

  .bold {
    font-size: 22px;
    line-height: 25px;
    margin-top: 15px;
  }
}

.bold {
  font-weight: bold;
}

.white {
  color: white;
}

.footer-container {
  padding-top: 0;
}

#custom-text {
  background: $grey-light-3;
  margin: 0;
  border: 1px solid $grey-light-3;
}

.contact-section {
  background: $grey-medium-light;
  padding: 40px 0;
}

.block-contact {
  margin: 0;
  padding: 0;
  color: black;
}

.custom-footer-link {
  padding: 40px 0;
  border-bottom: 1px solid $footer-border;
  margin-bottom: 48px;

  li {
    a {
      color: $cornflower-blue;

      &:hover {
        color: white;
      }
    }
  }

  .right-border {
    border-right: 1px solid $footer-border;
  }
}

.footer-container {
  .links {
    ul {
      margin-bottom: 25px;
    }
  }

  .block-social ul {
    margin-bottom: 25px;
  }

  .custom-footer-link {
    .col-sm-4 {
      text-align: center;
    }
  }
}

.subscribe-text {
  margin-bottom: 6px;
  margin-top: 20px;
  font-weight: bold;
}

.subscribe-newsletter {
  .form-control-label {
    margin: 0;
    text-transform: none;
    padding-top: 6px;
  }
}

.customer-form .form-control-label {
  text-transform: none;
}

#footer {
  z-index: 10;

  #authpopup.modal {
    padding-top: 258px;
  }
}

#footer {
  z-index: 10;

  #authpopup.modal {
    padding-top: 258px;
  }
}

header #pandemic-equipment-link {
  color: #C0C0C0;
  border: 1px solid #C0C0C0;
  background: white;
  box-shadow: none;
  position: absolute;
  right: -300px;
  top: -1px;
  z-index: 20;
}

.category-top {
  margin: 0;

  img {
    max-width: 100%;
  }

  .category-description {
    font-size: 12px;
    line-height: 24px;
    color: #50555C;
    text-align: left;
    margin: 12px 0;
  }

  .top-category-link {
    display: inline-block;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 6px 12px;
    color: #50555C;
    margin: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 46%;
      background: rgba(126, 125, 129, .35);
      z-index: 0;
      transition: width .3s cubic-bezier(.86, 0, .07, 1);
      width: 44px;
      height: 44px;
      border-radius: 22px;
      transform: translate3d(-22px, -50%, 0);
    }

    &:hover:before, &:focus:before {
      transition: width .3s cubic-bezier(.23, 1, .32, 1);
      width: calc(100% + 24px);
    }
  }
}

@import "old-right-cart";

@media (max-width: 1830px) {
  header #pandemic-equipment-link {
    right: auto;
    left: 0;
  }
}

@media(min-width: 1200px) {
  .custom-content-box {
    .left-half, .right-half {
      width: 40%;
      margin: 20px 21px;
      padding: 0;
    }
  }
}

@media(min-width: 1400px) {
  #right-side-cart-background,
  .right-cart-summary {
    width: 35%;
    min-width: 670px;
  }
}

@media(min-width: 768px) {
  #order-confirmation {
    .file-download-wrapper {
      margin-bottom: 20px;
    }

    #content-hook-order-confirmation-footer {
      margin-top: 50px;
    }
  }
  #module-crd_history-historyproducts, #module-crd_favorites-productlist {
    .file-download-wrapper {
      padding-bottom: 15px;

      .download-files {
        flex-direction: row-reverse;

        .file-download-btn-cont {
          width: auto;
          margin-left: 15px;
        }
      }
    }
  }
}

@media(max-width: 1199px) {
  .custom-content-box {
    .left-half, .right-half {
      width: initial;
      margin: 20px 21px;
      padding: 0;
    }
  }
  .gritter-notification-btns {
    display: none;
  }
  #gritter-notice-wrapper .gritter-close {
    display: none !important;
  }
  .top-menu[data-depth="0"] > li > a:after {
    content: none;
  }
  .header-top .search-widget form input[type='text'] {
    padding: 8px 10px 8px 20px;
    line-height: 20px;
  }
  header #pandemic-equipment-link {
    position: static;
    margin: 10px 0;
  }

  #products .product_show_list {
    .manufacturer {
      top: 5px;
      right: 130px;
    }

    .manufacturer.is-not-promoted {
      right: 10px;
      top: 5px;
    }
  }
}

.procurement-notification {
  .modal-dialog {
    margin: 25vh auto 0 auto;
    width: 550px;
  }
}

@media(max-width: 1080px) {
  .products .discount-container, #right-side-cart .article-content .discount-container {
    position: static;

    .info {
      bottom: -50px;
      top: auto;
      left: -5px;
      width: calc(100% + 10px);
    }
  }
  #right-side-cart .article-content {
    position: relative;
  }
}

@media (max-width: 1024px) {
  .info-popup {
    display: none;
  }
}

@media (max-width: 767px) {
  #header.sticky {
    margin-bottom: 70px;

    &.mobile-menu-opened {
      margin-bottom: 0;
    }
  }
  .procurement-notification {
    padding-right: 0 !important;

    .modal-dialog {
      width: 300px !important;
    }
  }
  #products .product-description .qty-box {
    width: 100%;
  }
  #header {
    .header-top .display-top-container {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1030;
      backface-visibility: hidden;
      padding: 10px 15px;
      background: #003B5C;
    }

    #_mobile_user_info.not-logged .btn {
      width: 132px;
      padding: 0 8px;
    }

    .header-nav .top-logo a img {
      margin: 0 auto;
    }

    #_mobile_user_info.logged {
      top: 70px;
    }
  }
  #pagenotfound header h1:after {
    content: none;
  }

  #confirmCheckout {
    .product-line-grid {
      height: inherit;
    }
  }

  .pull-right-mobile {
    float: right;
  }
  #mobile_top_menu_wrapper .top-menu .navbar-toggler {
    width: 35px;
    text-align: center;
  }
  .expandable-menu.top-menu li .popover .top-menu[data-depth="2"] > li {
    padding: 0;
  }
  #mobile_top_menu_wrapper .top-menu .sub-menu ul {
    margin: 0;
  }

  header #pandemic-equipment-link {
    display: block;
    width: 300px;
    margin: 0 auto 30px;
  }
  .back-btn {
    margin-bottom: 10px;
  }
  #order-confirmation {
    .order-donwload-btns {
      .file-download-btn-cont {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  #module-crd_history-historyproducts, #module-crd_favorites-productlist {
    .download-files {
      margin-bottom: 20px;
    }
  }
  .ordered-articles #search-top form {
    margin-bottom: 0;
  }
}

@media(min-width: 560px) and (max-width: 767px) {
  .supercheckout-totals {
    .align-left-mobile {
      text-align: left;
    }
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  body #header {
    .not-logged {
      #_desktop_language_selector {
        padding: 0 9px;
        border: none;
        margin: 8px 0;
      }

      .header-nav .language-selector {
        width: 29px;
      }

      .cart-language-container {
        width: 31px;
      }

      #_desktop_user_info {
        padding: 0;
      }
    }
  }
  #_desktop_language_selector {
    margin: 9px 0;
    padding-left: 19px;

    .dropdown-menu {
      left: auto !important;
      right: 0;
    }

    .fa-angle-down {
      font-size: 18px;
    }
  }
  .top-menu[data-depth="0"] {
    padding-left: 7px;

    > li a[data-depth="0"] {
      padding: 0 11px;
      font-size: 12px;
    }
  }
  .top-menu a[data-depth="0"] {
    padding: 18px 18px;
    padding-right: 20px;

    .icon-chevron {
      right: 2px;
      display: none;
    }
  }
  .icon-menu.tablet-menu {
    margin-right: 6px;
    display: none;
  }
  .file-download-btn-cont {
    max-width: 33.3%;
    position: relative;

    a {
      img {
        position: absolute;
        left: 0;
      }

      span {
        padding-left: 35px;
        display: block;
      }
    }
  }
  .products, .elementor {
    .manufacturer {
      right: 4px;
      top: 2px;
    }
  }
  .product-line-grid .prod-img-cont {
    padding-right: 15px;
  }
  .cart-item {
    .product-line-grid-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .limit-text {
      height: auto;
    }
  }
  .product-line-info {
    top: auto;
    transform: none;
  }
  #block-history .product-miniature.product_show_list .product-description .qty-box {
    padding-top: 0;
  }
  .top-menu[data-depth="0"] > li.has-children a[data-depth="0"] {
    padding: 18px 14px;
  }
  #products .product_show_list .product-description .right-div {
    align-items: center;
    justify-content: flex-end;
    height: 93px;
    display: flex;
  }
}

@media(max-width: 480px) {
  .products .discount-container .info {
    font-size: 12px;
  }
}

@include media-breakpoint-down(xs) {
  .product-line-grid {
    height: auto;
  }
  .placeorderButton {
    width: 100%;
  }
  .order-reference-heading {
    width: 33%;
  }
  #order-items {
    border-right: none;
  }
  .footer-container {
    .custom-footer-link {
      .col-sm-4 {
        text-align: left;

        ul {
          margin: 0;
        }
      }
    }
  }
}

/* START scroll top */
#scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #003B5C;
  width: 50px;
  height: 50px;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.9);

    i {
      color: #fff;
      top: 5px;
    }
  }
}

/* END scroll top */

#module-crd_favorites-productlist {
  .products-sort-order {
    .dropdown-menu {
      width: inherit;
    }
  }
}

#module-crd_outstandinginvoice-outstanding {

  thead {
    background-color: #F6F6F6;
    line-height: 1.5;
  }

  .pagination {
    div {
      line-height: inherit;
    }
  }
}

.btn-primary:focus {
  background-color: $crd-primary-color;
}

.category-cover {
  margin-top: 50px;
  @media (max-width: 768px) {
    margin: 50px -15px 0 -15px;
    .p15 {
        padding: 0 15px;
    }
  }
}